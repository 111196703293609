import React from 'react';
import styled, { css } from 'styled-components';

const CentredContainer = css`
	max-width: 775px;
	margin-right: auto;
	margin-left: auto;
	padding-left: 10px;
	padding-right: 10px;
`;

const Wrapper = styled.div`
	${CentredContainer}
`;

const HeroPageContentWrapper = ({ children }) => {
	return ( 
		<Wrapper>
			{ children }
		</Wrapper>
	 );
}
 
export default HeroPageContentWrapper;

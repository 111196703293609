export const languages = [
	{
		code: "en-GB",
		name: "English",
	},
	{
		code: "da-DK",
		name: "Dansk",
	},
	{
		code: "de-DE",
		name: "Deutsch",
	},
	{
		code: "es-ES",
		name: "Español",
	},
	{
		code: "fi-FI",
		name: "Suomi",
	},
	{
		code: "fr-FR",
		name: "Français",
	},
	{
		code: "it-IT",
		name: "Italiano",
	},
	{
		code: "nl-NL",
		name: "Nederlands",
	},
	{
		code: "no-NO",
		name: "Norsk",
	},
	{
		code: "pl-PL",
		name: "Polski",
	},
	{
		code: "pt-BR",
		name: "Português (Brasil)",
	},
	{
		code: "ru-RU",
		name: "Pусский",
	},
	{
		code: "sv-SE",
		name: "Svenska",
	},
	{
		code: "tr-TR",
		name: "Türkçesi",
	},
	{
		code: "zh-CN",
		name: "中文",
	},
]
import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
	max-width: ${({ theme }) => theme.sizes.contentContainer};
	width: 100%;
	display: flex;
	padding-top: 90px;

	@media print {
		flex-direction: column;
	}
`;

const PageContentWrapper = ({ children }) => <Wrapper>{children}</Wrapper>;

export default PageContentWrapper;

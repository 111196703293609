import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import LanguageSelect from './LanguageSelect';

const Button = styled.div`
	position: absolute;
	top: 20px;
	right: 20px;
	cursor: pointer;
`;

const Flag = styled.img`
	display: inline;
`;

const LanguageSelectBtn = ({ onClick }) => {

	const { i18n } = useTranslation();
	const [visible, setVisible] = React.useState(false);

	const handleToggleSelector = () => {
		setVisible(!visible);
	}

	return ( 
		<Button onClick={ handleToggleSelector }>
			<Flag src={`/images/flags/flag-${i18n.language}.svg`} />
			<LanguageSelect visible = { visible } toggleSelector={ handleToggleSelector } />
		</Button>	
	 );
}
 
export default LanguageSelectBtn;
import React from 'react';
import styled from 'styled-components';
import H3 from './typography/H3';
import H4 from './typography/H4';
import {ReactComponent as ShowMoreIcon} from '../images/icon-ellipsis.svg';
import { useTranslation } from 'react-i18next';

const Wrapper = styled.div`
	width: 100%;
`;

const Result = styled.div`
	position: relative;
	width: 100%;
	background-color: white;
	z-index: 1000;
	padding: 40px;
	margin-top: 25px;
	box-shadow: ${({ theme }) => theme.shadows.searchElements};
	border-radius: ${({ theme }) => theme.radii.xl};
	filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
`;

const Heading = styled.h1`
	font-size: ${({ theme }) => theme.fontSizes.l};
	color: ${({ theme }) => theme.colors.typography};
	font-family: ${({ theme }) => theme.fonts.slabBold};
`;

const Description = styled.div`
	font-size: ${({ theme }) => theme.fontSizes.m};
	color: ${({ theme }) => theme.colors.typography};
	font-family: ${({ theme }) => theme.fonts.regular};
	margin-top: 24px;
`;

const LinkContainer = styled.div`
	width: 100%,
`;

const Link = styled.a`
	color: blue;
	text-align: right;
`;

const SeverityContainer = styled.div`
	position: absolute;
	right: 28px;
	top: 32px;
`;

const Severity = styled.img`
	display: inline;
`;

const SeverityAlpha = styled.span`
	// Set to hidden for now but if AGCO request it is shown separately, we can turn this back on
	// For now, the severity alpha codes are shown on the actual icon
	display: none;
	position: relative;
	top: 6px;
	margin-left: 10px;
	font-family: ${({ theme }) => theme.fonts.slabBold};
	font-size: ${({ theme }) => theme.fontSizes.xl};
`;

const P = styled.p`
	line-height: 35px;
	text-align: right;
	margin-bottom: -15px;
	padding-bottom: 0px;
`;

const ExtraInfo = styled.div`
	padding-top: 20px;
`;

const ExtraInfoSection = styled.div`
	padding-top: 10px;
	padding-bottom: 10px;
`;

const ShowMoreButton = styled.div`
	position: absolute;
	bottom: 10px;
	right: 23px;
	padding: 10px;
	background-color: transparent;
	font-weight: bold;
	color: ${({ theme }) => theme.colors.AGCORed};
	cursor: pointer;
	& path {
		fill: green;
	}
	&:hover {
		text-decoration: underline;
		color: ${({ theme }) => theme.colors.AGCOGrayMid};
	}
`;

const StyledShowMoreIcon = styled(ShowMoreIcon)`
`;

const SearchResult = ({ data, code, description, severity }) => {

	const severityAlpha = ['A', 'B', 'C'];
	const [visible, setVisible] = React.useState(false);
	const { t } = useTranslation();

	function toggleVisible() {
		setVisible(!visible);
	}
	
	if (code == undefined)
		return null;

	return ( 
		<Wrapper>
			<Result>
				<Heading>{ code }</Heading>
				<SeverityContainer>
					<Severity src={ `/images/icons/icon-severity-${ severity }.png` } /> 
					<SeverityAlpha>{severityAlpha[ severity ]}</SeverityAlpha>
				</SeverityContainer>
				<Description>{ description }</Description>
				{ visible && (<ExtraInfo>
					<ExtraInfoSection>
						<H4>{ t("ui.cause").toUpperCase() }</H4>
						<span dangerouslySetInnerHTML={{__html: data.serviceInfo.cause}} />
					</ExtraInfoSection>
					<ExtraInfoSection>
						<H4>{ t("ui.remedy").toUpperCase() }</H4>
						<span dangerouslySetInnerHTML={{__html : data.serviceInfo.remedy}} />
					</ExtraInfoSection>
				</ExtraInfo>) }
				<ShowMoreButton onClick={ toggleVisible }>
					{ !visible ? t("ui.readmore") : t("ui.readless") }
				</ShowMoreButton>
			</Result>
		</Wrapper>
	 );
}
 
export default SearchResult;
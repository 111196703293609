import React from 'react';
import styled from 'styled-components';
import Header from './Header';

const Wrapper = styled.div`
	display: flex;
	min-height: 100vh;
	flex-direction: column;
`;

const Content = styled.div`
	position: relative;
	flex: 1;
	flex-direction: column;
	display: flex;
	max-width: 1440px;
	width: 100%;
	padding: 0 20px;
	align-self: left;
	flex-direction: column;
`;


const Page = ({ children, languageChange, langSelVisible, toggleLangSel }) => {

	return(
	<Wrapper>
		<Header langSel={toggleLangSel}/>
		<Content>{children}</Content>
	</Wrapper>
	);
}

export default Page;

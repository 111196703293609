import styled from 'styled-components';

export default styled.p`
	font-family: ${({ theme }) => theme.fonts.regular};
	line-height: ${({ theme }) => theme.lineHeights.m};
	margin-bottom: ${({ theme }) => theme.space.s};
	color: ${({ textColor }) => textColor ? textColor : ({ theme }) => theme.colors.AGCOBlack};
	letter-spacing: 0.06em;
	transition: all .5s;

	li & {
		margin-bottom: 0;
	}

	li & {
		&:first-of-type:not(:only-of-type) {
			margin-bottom: ${({ theme }) => theme.space.s};
		}
	}

	td & {
		font-size: ${({ theme }) => theme.fontSizes.s};
		line-height: 1.43;
	}

	.attention & {
		margin-bottom: ${({ theme }) => theme.space.xs};
	}

	@media print {
		font-size: 12px;
		line-height: 19.43px;
	}
`;
import React from 'react';
import styled from 'styled-components';
import { useTable } from 'react-table';
import { CSVLink } from 'react-csv';

const ExportLink = styled(CSVLink)`
	display: block;
	background-color: ${({ theme }) => theme.colors.AGCORed};
	max-width: 200px;
	padding: 20px;
	margin-top: 20px;
	text-align: center;
	color: ${({ theme }) => theme.colors.AGCOWhite};
	font-weight: bolder;
`;

const Export = ({ data }) => {
	let manipulatedData = []
	const severities = ["A", "B", "C"];
	// Convert to individual items so we can filter out properties we no longer need or want
	data.forEach(item => {
		manipulatedData.push(
			{
				Code: item.code,
				Alias: item.alias,
				Description: item.description,
				Severity: severities[item.severity],
			}
		)
	});
	const csvData = React.useMemo(() => manipulatedData, [manipulatedData]);

	return (
		<>
		<ExportLink data={csvData}>Export CSV</ExportLink>
		</>
	);
}

export default Export;
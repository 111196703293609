import React from 'react';
import styled from 'styled-components';
import Export from '../components/Export';
import Page from '../components/layout/Page';
import PageContentWrapper from '../components/layout/PageContentWrapper';
import Search from '../components/Search';
import data from '../data/data.json';

const Exporter = () => {
	return ( 
		<Page>
				<Export data={ data.codes } />
			
		</Page>
	 );
}
 
export default Exporter;
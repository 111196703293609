import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.span`
	position: relative;
	background-color: ${({ theme }) => theme.colors.AGCOGrayLight};
	padding-top: 24px;
	padding-bottom: 24px;
	padding-left: 41px;
	max-height: 88px;

	@media print {
		color-adjust: exact !important;
		-webkit-print-color-adjust: exact !important;
	}
`;

const Logo = styled.img`
	height: 40px;
	display: inline;
`;

const Linklist = styled.ul`
	list-style: none;
	display: inline;
	margin-left: 545px;

	@media print {
		display: none;
	}
`;

const LinklistItem = styled.li`
	position: relative;
	display: inline;
	font-family: ${({ theme }) => theme.fonts.regular};
	font-size: 14px;
	font-weight: 400;
	color: ${({ theme }) => theme.colors.AGCORed};
	margin-right: 43px;
	cursor: pointer;
`;

const InlineIcon = styled.img`
	display: inline;
	margin-right: 6px;
	max-width: 16px;
	&[data-align='right'] {
		margin-right: 0px;
		margin-left: 13px;
	}
`;

const TechConnectLink = styled.a`

`;

const Header = () => {

	return (
		<Wrapper>
			<Logo src={'/images/agco-logo.png'} />
			{/* <Linklist>
				<LinklistItem>
					<TechConnectLink href='https://techconnect.agcocorp.com/' target='_blank'>
						<InlineIcon src={'/images/icons/icon-techconnect.svg'} /> Tech Connect{' '}
						<InlineIcon
							data-align={'right'}
							src={'/images/icons/icon-chevron-right-agcored.svg'}
						/>
					</TechConnectLink>
				</LinklistItem>
			</Linklist> */}
		</Wrapper>
	);
};

export default Header;
